// Type assertion to declare window with dataLayer and gtag
declare global {
    interface Window {
        dataLayer: any[];
        gtag?: (...args: any[]) => void;
    }
}

// Function to load Google Tag Manager script and return a Promise
const loadGoogleTagManagerScript = (measurementId: string): Promise<void> => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}&v=` + new Date().toISOString();
        script.async = true;
        script.onload = () => {
            console.log("Google Tag Manager script loaded");
            resolve();
        };
        script.onerror = () => {
            reject(new Error("Failed to load Google Tag Manager script"));
        };
        document.head.appendChild(script);
    });
};

// Initialize Google Analytics
const initializeGA = (measurementId: string) => {
    // Initialize dataLayer and gtag function
    window.dataLayer = window.dataLayer || [];
    window.gtag = function (...args: any[]) {
        window.dataLayer.push(args);
    };

    console.log("initialize GA", measurementId);

    // Load Google Tag Manager script and configure GA
    loadGoogleTagManagerScript(measurementId).then(() => {
        if (window.gtag) {
            window.gtag('js', new Date());
            window.gtag('config', measurementId);
        }
    }).catch((error) => {
        console.error(error);
    });
};

const eventCallingGA = (sendTo: string) => {
    // Wait until the Google Analytics script is loaded and gtag is available
    window.gtag('event', 'conversion', {
        'allow_custom_scripts': true,
        'send_to': sendTo
    });
    // const noscript = `<noscript>
    //             <img src="https://ad.doubleclick.net/ddm/activity/src=9933106;type=audia0;cat=audia000;dc_lat=;dc_rdid=;tag_for_child_directed_treatment=;tfua=;npa=;gdpr=${GDPR};gdpr_consent=${GDPR_CONSENT_755};ord=1?" width="1" height="1" alt=""/>
    //             </noscript>`;
    // let tempDiv = document.createElement("div");
    // tempDiv.innerHTML=noscript;
    // document.body.appendChild(tempDiv);
    console.log("conversion trigger", window.gtag);
}

export { initializeGA, eventCallingGA };
