import {
    FormControl,
    FormLabel,
    FormErrorMessage,
    Input,
    Center,
    Button,
    Modal,
    ModalOverlay,
    ModalContent,
    ModalHeader,
    ModalBody,
    useDisclosure,
    Grid,
    Select
} from '@chakra-ui/react';
import React, { useEffect, useState, ChangeEvent, FormEvent } from 'react';
import { openCustomerModal, customerInfo } from 'src/redux/reducers/CustomerFormReducer';
import { useAppDispatch, useAppSelector } from 'src/redux/hooks';
import { useNavigate,useLocation } from 'react-router-dom';
import Cookies from 'js-cookie';
import { updateDealer } from 'src/redux/reducers/cityListReducer';
import {eventCallingGA} from 'src/lib/ga'
import {eventCallingGAdWords} from 'src/lib/gaAdWords'
import {eventMeta} from 'src/lib/meta'



const styles = {
    title: {
        color: '#343a40',
        fontSize: '20px',
        fontWeight: 'semibold',
        textTransform: 'uppercase',
        mx: "auto"
    },
    formControl: {
        mb: '40px'
    },
    hgroup: {
        mb: '40px',
        justifyContent: "center",
        alignItems: "center"
    },
    hgroupIcon: {
        justifySelf: 'flex-start',
    },
    linkText: {
        color: 'textClr2',
        fontSize: "16px",
        fontWeight: 'medium',
        textAlign: 'center'
    },
    link: {
        textDecoration: 'underline',
        _hover: {
            color: 'primary'
        }
    },
    formGrid: {
        gridTemplateColumns: { xl: 'repeat(2,1fr)' },
        rowGap: { base: 4, xl: 4 },
        columnGap: { xl: 10 },
        mb: { base: 2, xl: 2 }
    },
    mobileGrid: {
        gridTemplateColumns: '1fr .3fr',
        gap: 10
    },
    regText: {
        p: "0.4rem 0.8rem",
        fontSize: "12px",
        fontWeight: 'medium'
    },
    regLink: {
        display: 'block',
        textDecoration: 'underline',
        cursor: 'pointer',
        _hover: {
            color: 'primary',
        }
    },
    modalContent: {
        p: ['20px', '20px'],
        borderRadius: "none"
    },
    modalHeader: {
        p: 0,
        color: 'textClr2',
        fontSize: 'xl',
        textTransform: 'uppercase',
        fontWeight: 'medium',
        textAlign: 'center',
        mb: 5
    },
    modalBody: {
        p: 0,
    },
    modalSubttl: {
        fontSize: 'md',
        color: 'textClr2',
        textTransform: 'uppercase',
        fontWeight: 'medium',
        mb: 4
    },
    modalBtn: {},

}

export const CustomerModal = () => {
    const dispatch = useAppDispatch();
    const navigate = useNavigate();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const openCustomerModal = useAppSelector((state: any) => state.customer.openCustomerModal);
    const cityId = useAppSelector((state: any) => state.cities.cityId);
    const [submitBtnLoading, setSubmitBtnLoading] = useState<boolean>(false);
    const [isFormSubmitted, setIsFormSubmitted] = useState(false);
    const [firstName, setFirstName] = useState<string>("");
    const [lastname, setLastName] = useState<string>("");
    const [invalidFistName, setInvalidFirstName] = useState<boolean>(false);
    const [invalidLastName, setInvalidLastName] = useState<boolean>(false);
    const [mobileNo, setMobileNo] = useState<string>("");
    const [invalidMobile, setInvalidMobile] = useState<boolean>(false);
    const [email, setEmail] = useState<string>("");
    const [dealer, setDealer] = useState<string>("");
    const includesSubDomain = useAppSelector((state: any) => state.commonData.includesSubDomain);
    const dealerList = useAppSelector((state: any) => state.dealer.listByCity.data);
    const [multipleDealers, setMultipleDealer] = useState<boolean>(false);
    const LSdealer = Cookies.get('dealer');
    const location = useLocation();

    useEffect(() => {
        if (openCustomerModal) {
            onOpen();
        } else {
            onClose();
        }
    }, [openCustomerModal, onOpen, onClose]);

    useEffect(() => {
  
        if (dealerList && dealerList.length > 1) {
            setMultipleDealer(true);
        }
        else if (dealerList && dealerList.length === 1) {
            setMultipleDealer(false);
            // const [singleDealer] = dealerList;
            // dispatch(updateDealer(singleDealer.id));
          }
    }, [dealerList]);


    const validateMobileNo = (e: ChangeEvent<HTMLInputElement>) => {
        // const re = /^[0-9\b]+$/;
        // if (e.target.value === '' || re.test(e.target.value)) {
        //     setMobileNo(e.target.value)
        // }
        const mobileRegex = /^[1-9]\d{9}$/; // Regular expression for Indian mobile numbers
        const isValidMobile = mobileRegex.test(e.target.value);
        setInvalidMobile(!isValidMobile);
        setMobileNo(e.target.value);
    }
    const handleUpdateDealer = (e: ChangeEvent<HTMLSelectElement>) => {
        setDealer(e.target.value);
       // dispatch(updateDealer(e.target.value));
            
    }
    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        if (isFormSubmitted) {
            return;
          }
      
        setSubmitBtnLoading(true);
        

        if (firstName.length === 0) {
            setInvalidFirstName(true)
        }
        if (lastname.length === 0) {
            setInvalidLastName(true)
        }
        const params = {
            "first_name": firstName,
            "last_name": lastname,
            "mobile": mobileNo,
            "email": email,
            "city": cityId,
            "platform": process.env.REACT_APP_PLATFORM_ID,
            "is_subdomain": includesSubDomain ? true : false,
            "dealer_id": includesSubDomain ? LSdealer : dealer ? dealer : ""
        }

        Cookies.set('cust', JSON.stringify(params), { expires: 1 });
        const res = dispatch(customerInfo(params));
        res.then((data: any) => {
            if (data.payload.data.status === "success") {
                eventCallingGA('DC-9933106/audia0/audia000+standard');
                console.log("calling ga event");
                eventCallingGAdWords("AW-923778505/BO2oCMvU_LsZEMn7vrgD");
                console.log("calling ga ad word event");
                eventMeta('389066024760021','SubmitApplication');
                console.log("calling meta event");
                // if (window.location.hostname.includes('noida.audiapprovedplus')) {
                //     console.log("hii");
                //     fbq('init', '307433628486132');
                //     fbq('track', 'PageView');
                //     fbq('track', 'Lead');
                // }
                onClose();
                if (location.pathname !== '/sell') {
                    navigate('/buy');
                  } 
                window.location.reload();
            } else {
                alert("error occured");
            }
        })
        setIsFormSubmitted(true);
        setSubmitBtnLoading(false);
    }

    return (
        <div>
            <Modal isOpen={isOpen} onClose={onClose} size={'xl'} closeOnEsc={false} closeOnOverlayClick={false}>
                <ModalOverlay/>
                <ModalContent sx={styles.modalContent}>
                    <ModalHeader sx={styles.modalHeader}>Enter Your Details</ModalHeader>
                    <ModalBody sx={styles.modalBody}>
                        <form onSubmit={handleSubmit}>
                            <Grid sx={styles.formGrid}>
                                <FormControl sx={styles.formControl} variant="floating" isRequired isInvalid={invalidFistName}>
                                    <Input name='firstName' type='text' placeholder=" " value={firstName} onChange={(e: ChangeEvent<HTMLInputElement>) => setFirstName(e.target.value)} />
                                    <FormLabel htmlFor='firstName'>First Name</FormLabel>
                                    <FormErrorMessage>Your name shouldn't be blank.</FormErrorMessage>
                                </FormControl>
                                <FormControl sx={styles.formControl} variant="floating" isRequired isInvalid={invalidLastName}>
                                    <Input name='lastName' type='text' placeholder=" " value={lastname} onChange={(e: ChangeEvent<HTMLInputElement>) => setLastName(e.target.value)} />
                                    <FormLabel htmlFor='lastName'>Last Name</FormLabel>
                                    <FormErrorMessage>Your name shouldn't be blank.</FormErrorMessage>
                                </FormControl>
                                <FormControl sx={styles.formControl} variant="floating" isRequired isInvalid={invalidMobile}>
                                    <Input name='mobile' type='tel' placeholder=" " value={mobileNo} maxLength={10} onChange={validateMobileNo} />
                                    <FormLabel htmlFor='mobile'>Mobile</FormLabel>
                                    <FormErrorMessage>Invalid mobile number</FormErrorMessage>
                                </FormControl>
                                <FormControl sx={styles.formControl} variant="floating" isRequired isInvalid={false}>
                                    <Input name='email' type='email' placeholder=" " value={email} onChange={(e: ChangeEvent<HTMLInputElement>) => setEmail(e.target.value)} />
                                    <FormLabel htmlFor='email'>Email</FormLabel>
                                </FormControl>
                                {multipleDealers &&  !includesSubDomain &&  <FormControl sx={styles.formControl} variant="floating" isInvalid={false}>
                                    <Select variant="mySearch" placeholder='Select Dealer' onChange={handleUpdateDealer} isRequired>
                                        {
                                            dealerList.length !== 0 ? dealerList.map((item: any) => (<option
                               key={item.id} value={item.id}>{item.name}</option>)) : <option>Loading</option>
                                        }
                                    </Select>
                                </FormControl>}
                            </Grid>
                            <Center>
                                <Button
                                    type='submit'
                                    variant={'primary'}
                                    size="lg"
                                    isLoading={submitBtnLoading}
                                    loadingText='Submitting'
                                    // disabled={!verifyOTP}
                                    disabled={invalidMobile}
                                >Continue</Button>
                            </Center>
                        </form>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </div>
    )
}
