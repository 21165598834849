// Type assertion to declare window with dataLayer and gtag
declare global {
    interface Window {
        dataLayer: any[];
        gtag?: (...args: any[]) => void;
    }
}

// Function to load Google Tag Manager script and return a Promise
const loadGoogleTagManagerScript = (measurementId: string): Promise<void> => {
    return new Promise((resolve, reject) => {
        const script = document.createElement('script');
        script.src = `https://www.googletagmanager.com/gtag/js?id=${measurementId}&v=` + new Date().toISOString();
        script.async = true;
        script.onload = () => {
            console.log("Google Tag Manager AWord script loaded");
            resolve();
        };
        script.onerror = () => {
            reject(new Error("Failed to load Google Tag Manager script"));
        };
        document.head.appendChild(script);
    });
};

// Initialize Google Analytics
const initializeGAdWords = (measurementId: string) => {
    // Initialize dataLayer and gtag function
    window.dataLayer = window.dataLayer || [];
    window.gtag = function (...args: any[]) {
        window.dataLayer.push(args);
    };

    console.log("initialize GAdWords", measurementId);

    // Load Google Tag Manager script and configure GAdWords
    loadGoogleTagManagerScript(measurementId).then(() => {
        if (window.gtag) {
            window.gtag('js', new Date());
            window.gtag('config', measurementId);
        }
    }).catch((error) => {
        console.error(error);
    });
};

const eventCallingGAdWords = (sendTo: string) => {
    function gtag_report_conversion(url: any) {
        // console.log(url,"ddlksdj");
        var callback = function () {
            if (typeof (url) != 'undefined') {
                window.location = url;
            }
        };
        gtag('event', 'conversion', {
            'send_to': sendTo,
            'event_callback': callback
        });
        return false;
    }
    gtag_report_conversion(window.location);
    console.log("conversion trigger AW", window.gtag);
}

export { initializeGAdWords, eventCallingGAdWords };
